/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { membersOf } from "../assets/data";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="ftr_cont_box1">
              <h2 class="ftr_head">Member of</h2>
              <ul style={{ flexWrap: "wrap" }}>
                {membersOf?.map((member, index) => (
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() => window.open(member.link)}
                    key={member.id}
                  >
                    <img
                      src={member.image}
                      alt=""
                      style={{
                        width: index === 1 ? "175px" : "150px",
                        height: index === 2 ? "90px" : "100px",
                        backgroundColor: index === 2 ? "transparent" : "white",
                      }}
                    />
                    {index === 0 && <br />}
                  </li>
                ))}
                {/* <li>
                  <img
                    src={require("../assets/images/home/ftrimg1.png")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../assets/images/home/ftrimg2.png")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../assets/images/home/ftrimg2.png")}
                    alt=""
                  />
                </li> */}
              </ul>
            </div>
            <div class="ftr_cont_box1" style={{ marginTop: "20px" }}>
              <h2 class="ftr_head">
                Preferred <br />
                Insurance Brokers:
              </h2>
              <ul>
                <li>
                  <img
                    src={require("../assets/images/home/insurance.png")}
                    alt=""
                    style={{ height: "50px", width: "150px" }}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-2">
            <h2 class="ftr_head">Quick Links</h2>
            <div class="ftr_content_box02">
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#" onClick={() => navigate("/about")}>
                    About Us
                  </a>
                </li>

                <li>
                  <a href="#pro-ad">PRO-Advantages</a>
                </li>
                <li>
                  <a href="#our-partner">Our Partners </a>
                </li>
                <li>
                  <a href="#" onClick={() => navigate("/testimonial")}>
                    Testimonials
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => navigate("/contact-us")}>
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://nucleusprolognet.s3.amazonaws.com/admin-uploads/privacy+Policy+2022.pdf"
                      )
                    }
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-2" id="footer">
            <h2 class="ftr_head">Our Network</h2>
            <div class="ftr_content_box01">
              <ul>
                <li>
                  <a
                    href="#footer"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    {" "}
                    North America{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="#footer"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    {" "}
                    Indian sub continent
                  </a>
                </li>
                <li>
                  <a
                    href="#footer"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    Africa
                  </a>
                </li>
                <li>
                  <a
                    href="#footer"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    {" "}
                    Far East{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="#footer"
                    style={{ cursor: "default" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    ...More centre's opening up
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-2">
            <h2 class="ftr_head">Contact Us</h2>
            <div class="ftr_contact_ifno">
              <a href="tel:14372147577;">+1 (437) 214-7577 </a>
              <br />
              <a href="mailto:info@prolognet.com;">info@prolognet.com </a>
              <div class="d-flex apple_store_link">
                <img
                  src={require("../assets/images/home/apple.png")}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/us/app/professional-logistics-network/id6446221546?platform=iphone",
                      "_blank"
                    )
                  }
                />
                <img
                  src={require("../assets/images/home/googleplay.png")}
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.nucleus.prolognet",
                      "_blank"
                    )
                  }
                />
              </div>
              <ul>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      window.open("https://www.facebook.com/prolognetwork/")
                    }
                  >
                    <i class="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      window.open("https://www.instagram.com/prolog_net/")
                    }
                  >
                    <i class="bx bxl-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/professional-logistics-network-inc-prolog/"
                      )
                    }
                  >
                    <i class="bx bxl-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="ftr_copyrightes" style={{ marginTop: "40px" }}>
          <p className="notranslate">
            © {moment().format("YYYY")} PROLOG. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
