import { Backdrop, CircularProgress, Drawer, Snackbar } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import CSSLink from "../components/CSSLink";
import Header from "../components/Header";
import { BASE_URL } from "../constants/config";
import MuiAlert from "@mui/material/Alert";
import Menu from "./Menu";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import Select from "react-select";
import Footer from "../components/Footer";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RequestDemo = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");

  const clear = () => {
    setName("");
    setCompanyName("");
    setEmail("");
    setContactNumber("");
    setReason("");
    setMessage("");
  };

  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const [error, seterror] = useState(false);

  const OnSubmit = (data) => {
    seterror(false);
    console.log(data, "data");
    if (
      name === "" ||
      companyName === "" ||
      email === "" ||
      contactNumber === "" ||
      message === "" ||
      selectedCountry === null
    ) {
      seterror(true);
      console.log(
        name,
        companyName,
        email,
        contactNumber,
        reason,
        message,
        selectedCountry
      );
    } else {
      setloading(true);
      axios
        .post(`${BASE_URL}/api/contactus/send_demoreq`, {
          name: name,
          companyName: companyName,
          email: email,
          contactNumber: contactNumber,
          reason: reason,
          message: message,
          country: selectedCountry,
        })
        .then((res) => {
          setloading(false);
          setOpen(true);
          clear();
        })
        .catch((error) => {
          console.log(error);
          setloading(false);
          setOpen(false);
        });
    }
  };

  const [openMenu, setopenMenu] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  const [countryCode, setcountryCode] = useState("");

  useEffect(() => {
    axios
      .get("https://restcountries.com/v2/all")
      .then((response) => {
        const countryOptions = response.data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
          code: country.callingCodes[0],
        }));
        setCountries(countryOptions);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const headers = {
    "X-CSCAPI-KEY": "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
  };

  const GetCities = (code) => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries/${code}/cities`, {
        headers,
      })
      .then((response) => {
        const cityOptions = response.data.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setCities(cityOptions);
      })
      .catch((error) => {
        console.error("Error fetching cities:", error);
      });
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    GetCities(selectedOption.value);
    setSelectedCity(null);
    setcountryCode(selectedOption.code);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  return (
    <section>
      <CSSLink />
      <Header open={openMenu} setOpen={setopenMenu} main={false} />
      <div class="page-13">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          {/* <CircularProgress color="inherit" /> */}
          <img
            src={require("../assets/images/loader.gif")}
            style={{ height: "175px", width: "175px" }}
          />
        </Backdrop>
        <Drawer
          anchor={"left"}
          open={openMenu}
          PaperProps={{
            sx: { width: "100%" },
          }}
          onClose={() => setopenMenu(false)}
        >
          <Menu open={openMenu} setOpen={setopenMenu} />
        </Drawer>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Request sent!
          </Alert>
        </Snackbar>
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity="error"
            sx={{ width: "100%" }}
          >
            Please fill all the fields!
          </Alert>
        </Snackbar>

        <main>
          <div class="contents">
            <h1>Request for a Demo</h1>
            <div class="text-input">
              <input
                type="text"
                placeholder="Company Name"
                name="company"
                // {...register("company", { required: true })}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div
              className="text-input"
              style={{ marginTop: "1rem", paddingBottom: "0.4rem" }}
            >
              <Select
                options={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Select Country"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent",
                    border: "none",
                    width: "480px",
                  }),
                  input: (provided) => ({
                    ...provided,
                    width: "100%",
                    background: "none",
                    border: "none",
                    outline: "none",
                    color: "#ffffff",
                    fontSize: "1em",
                    backgroundColor: "transparent",
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#ffffff",
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "#ffffff",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    padding: "0px",
                    outline: "none",
                  }),
                }}
              />
            </div>
            {selectedCountry && (
              <div
                className="text-input"
                style={{ marginTop: "1rem", paddingBottom: "0.4rem" }}
              >
                <Select
                  options={cities}
                  value={selectedCity}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                      border: "none",
                      width: "480px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      width: "100%",
                      background: "none",
                      border: "none",
                      outline: "none",
                      color: "#ffffff",
                      fontSize: "1em",
                      backgroundColor: "transparent",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#ffffff",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#ffffff",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0px",
                      outline: "none",
                    }),
                  }}
                />
              </div>
            )}
            <div class="text-input">
              <input
                type="text"
                placeholder="Contact Name"
                name="name"
                // {...register("name", { required: true })}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div class="text-input">
              <input
                type="designation"
                placeholder="Designation"
                name="email"
                // {...register("email", { required: true })}
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </div>
            <div class="text-input">
              <input
                type="email"
                placeholder="Email"
                name="email"
                // {...register("email", { required: true })}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              class="text-input"
              style={{
                display: countryCode === "" ? "block" : "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {countryCode && (
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  +{countryCode}
                </p>
              )}
              <input
                type="tel"
                placeholder="Office Number"
                name="Office"
                value={officeNumber}
                onChange={(e) => setOfficeNumber(e.target.value)}
              />
            </div>
            <div
              class="text-input"
              style={{
                display: countryCode === "" ? "block" : "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {countryCode && (
                <p
                  style={{
                    marginBottom: "0px",
                    color: "#fff",
                    marginRight: "5px",
                  }}
                >
                  +{countryCode}
                </p>
              )}
              <input
                style={{ width: "90%" }}
                type="tel"
                placeholder="Cell Number"
                name="phone"
                // {...register("phone", { required: true })}
                value={contactNumber}
                onChange={(e) => {
                  setContactNumber(e.target.value);
                }}
              />
            </div>
            {/* <div class="text-input">
              <input
                type="text"
                placeholder="Reason"
                name="reason"
                // {...register("reason", { required: true })}
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div> */}
            <div class="text-input">
              <input
                type="text"
                placeholder="Message"
                name="message"
                // {...register("message", { required: true })}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <div class="privacy">
              <p style={{ textAlign: "center" }}>
                By clicking submit you agree to Professional Logistics Network’s
                Privacy Policy
              </p>
            </div>
            <button
              style={{
                display: "inline-block",
                position: "relative",
                padding: "0",
                border: "none",
                backgroundColor: "transparent",
                cursor: "pointer",
                outline: "none",
              }}
              onClick={handleSubmit(OnSubmit)}
            >
              <img
                src={require("../assets/images/arrow-down.png")}
                style={{
                  display: "block",
                  width: "500px",
                  height: "150px",
                }}
              />
              <span
                style={{
                  position: "absolute",
                  top: "35%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Submit
              </span>
            </button>
          </div>
        </main>
      </div>

      <Footer />
    </section>
  );
};

export default RequestDemo;
