// export const BASE_URL = "https://backend.prolognet.com";

export const BASE_URL = "http://34.232.157.181:3005";
// Staging: http://34.228.248.86:3000
// new staging: http://34.232.157.181:3000
// Production: https://backend.prolognet.com

// export const redirect = "http://app.prolognet.com/";

export const redirect = "http://app.staging.prolognet.com";
// export const staging = "http://app.staging.prolognet.com";
// export const prod = "http://app.prolognet.com/";
