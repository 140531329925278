/* eslint-disable jsx-a11y/alt-text */
import { Drawer, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import {
  AboutUsBulletPoints,
  SetUsApartBulletPoints,
  aboutUs,
} from "../assets/data";

import CSSLink from "../components/CSSLink";
import Header from "../components/Header";
import Menu from "./Menu";
import Footer from "../components/Footer";

const About = () => {
  const [openMenu, setopenMenu] = useState(false);

  const isMobile = useMediaQuery("(min-width:700px)");
  const isSmall = useMediaQuery("(max-width:500px)");

  const verySmall = useMediaQuery("(max-width:480px)");

  return (
    <section>
      <CSSLink />
      <Header />
      <div className="page-3">
        <Drawer
          anchor={"left"}
          open={openMenu}
          PaperProps={{
            sx: { width: "100%" },
          }}
          onClose={() => setopenMenu(false)}
        >
          <Menu open={openMenu} setOpen={setopenMenu} />
        </Drawer>
        <main>
          <div class="contents">
            <div
              class="heading"
              data-aos="fade-up"
              style={{
                display: verySmall && "flex",
                justifyContent: verySmall && "center",
                alignItems: verySmall && "center",
                backgroundImage:
                  verySmall &&
                  "radial-gradient( at top, #182977 1%, #182977 15%, #0f1637 90% )",
                padding: verySmall && "3rem 0rem",
                position: verySmall && "relative",
              }}
            >
              <h1
                data-aos="fade-right"
                style={{
                  paddingLeft: verySmall && "0px",
                  marginTop: verySmall && "2rem",
                }}
              >
                About-us
              </h1>
            </div>
            <div
              class="content"
              data-aos="fade-down"
              style={{
                marginRight: !isMobile && "0px",
                marginLeft: !isMobile && "0px",
                paddingBottom: "0%",
              }}
            >
              <div class="portion" style={{ paddingTop: "20px" }}>
                {aboutUs.map((item, index) => {
                  return (
                    index % 2 === 0 && (
                      <>
                        <div
                          class=""
                          style={{
                            display: "flex",
                            flexDirection: isMobile ? "row" : "column",
                            justifyContent: !isMobile && "center",
                            alignItems: !isMobile && "center",
                            marginTop: "5%",
                          }}
                        >
                          <div
                            class=""
                            style={{ textAlign: "right", marginRight: "20px" }}
                          >
                            <h2
                              data-aos="fade-right"
                              style={{
                                color: "white",
                                fontSize: "24px",
                                fontFamily: "montserrat-bold",
                              }}
                            >
                              {item.title}
                            </h2>
                            <h5
                              data-aos="flip-up"
                              style={{
                                color: "white",
                                fontSize: "16px",
                                marginTop: "2.5%",
                              }}
                            >
                              {item.subtitle}
                            </h5>
                            {item.title === "About Us" && (
                              <>
                                <div className="aboutus-bullet-points-container">
                                  {AboutUsBulletPoints.map((bullet) => {
                                    return (
                                      <div
                                        className="aboutus-bullet-points"
                                        key={bullet.id}
                                      >
                                        <img
                                          src={bullet.img}
                                          style={{ height: 50, width: 50 }}
                                        />
                                        <h4>{bullet.heading}</h4>
                                        <p>{bullet.text}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                                <h5
                                  data-aos="flip-up"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    marginTop: "2.5%",
                                  }}
                                >
                                  We are the only network that invites you to
                                  for a walkthrough on our live website/member
                                  area before you can take a decision on whether
                                  to join or not.
                                </h5>
                              </>
                            )}
                          </div>
                          <img
                            src={item.image}
                            alt=""
                            data-aos="fade-left"
                            style={{
                              width: isSmall ? "40%" : "20%",
                              height: isSmall ? "45%" : "25%",
                              marginTop: !isMobile && "5%",
                              boxShadow: "0px 0px 30px 4px #00267e",
                            }}
                          />
                        </div>
                        <hr data-aos="flip-right" style={{ width: "85%" }} />
                        {aboutUs?.length > index + 1 && (
                          <>
                            <div
                              class=""
                              style={{
                                display: "flex",
                                flexDirection: isMobile ? "row" : "column",
                                justifyContent: !isMobile && "center",
                                alignItems: !isMobile && "center",
                                marginTop: "5%",
                              }}
                            >
                              <img
                                src={aboutUs[index + 1].image}
                                alt=""
                                data-aos="fade-right"
                                style={{
                                  width: isSmall ? "40%" : "20%",
                                  height: isSmall ? "45%" : "25%",
                                  marginTop: !isMobile && "5%",
                                  marginBottom: "2.5%",
                                  boxShadow: "0px 0px 30px 4px #00267e",
                                }}
                              />
                              <div
                                class=""
                                style={{
                                  textAlign: "left",
                                  marginLeft: "20px",
                                }}
                              >
                                <h2
                                  data-aos="fade-left"
                                  style={{
                                    color: "white",
                                    fontSize: "24px",
                                    fontFamily: "montserrat-bold",
                                  }}
                                >
                                  {aboutUs[index + 1].title}
                                </h2>
                                <h5
                                  data-aos="flip-up"
                                  style={{
                                    color: "white",
                                    fontSize: "16px",
                                    marginTop: "2.5%",
                                  }}
                                >
                                  {aboutUs[index + 1].subtitle}
                                </h5>
                                {aboutUs[index + 1].title ===
                                  "What sets us apart" && (
                                  <>
                                    <div className="aboutus-bullet-points-container">
                                      {SetUsApartBulletPoints.map((bullet) => {
                                        return (
                                          <div
                                            className="aboutus-bullet-points"
                                            key={bullet.id}
                                          >
                                            <img
                                              src={bullet.img}
                                              style={{ height: 50, width: 50 }}
                                            />
                                            <h4>{bullet.heading}</h4>
                                            <p>{bullet.text}</p>
                                          </div>
                                        );
                                      })}
                                    </div>
                                    <h5
                                      data-aos="flip-up"
                                      style={{
                                        color: "white",
                                        fontSize: "16px",
                                        marginTop: "2.5%",
                                      }}
                                    >
                                      By combining these elements, we have
                                      created a network that not only meets but
                                      surpasses the expectations of users,
                                      earning the distinction of being the
                                      NEXT-Gen Network.
                                    </h5>
                                  </>
                                )}
                              </div>
                            </div>
                            <hr
                              data-aos="flip-right"
                              style={{ width: "85%" }}
                            />
                          </>
                        )}
                      </>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </section>
  );
};

export default About;
